<template>
  <div class="flex flex-col h-screen">
    <AppHeader />
    <div class="relative bg-white z-1 flex-1">
      <div class="container mx-auto h-full">
        <slot />
      </div>
    </div>
  </div>
</template>
